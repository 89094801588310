import React from "react";
import { graphql, Link } from "gatsby";
import "../styles/styles.css"
// import { GatsbyImage } from 'gatsby-plugin-image';
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import NavBarTwo from "../components/NavbarTwo";
import Footer from "../components/Footer";
import { SEO } from "../components/seo";
import Topics from "../components/Topics";
import ContactDetails from "../components/ContactDetails";

const Container = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
gap: 4rem;
grid-auto-rows: auto auto 500px;

}
`


const aboutPage = ({data})=>{
   
    return (
        
        <>

<NavBarTwo/>


<section className="bg-hero-marktplaetze lg:bg-hero-marktplaetze-xl flex flex-col justify-end sm:block pb-16 text-gray-400 bg-gray-900 body-font bg-no-repeat  bg-center	 bg-cover	 h-screen">
    <div className="container mx-auto max-w-7xl flex  px-5 sm:py-64 sm:flex-row flex-col items-center">
      <div className="lg:flex-grow max-w-sm md:max-w-lg md:w-1/2 -lg:pr-24 -md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center  bg-dark/40 py-6 px-4 rounded">
      <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
        <h1 className="title-font sm:text-5xl text-3xl mb-4 font-medium text-white">Mehr Reichweite
          <br/><span className="text-primary">
          im Onlinehandel</span>
        </h1>
        <br/>
        <p className="text-sm md:text-base mb-8 leading-relaxed text-white max-w-sm">Über Online-Marktplätze neue Käuferschichten und geographische Märkte (beinahe) auf Knopfdruck erschließen.</p>
        <div className="flex-col lg:flex-row justify-center">
          <a href="#main" className=" inline-flex text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primary rounded-full text-lg mb-6">mehr erfahren
            </a>

          <a href="#contacts" className="sm:ml-0 lg:ml-4  inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded-full text-lg">Kontaktaufnahme</a>
        </div>
      </div>
    
    </div>
  </section>      
            

<section className="text-gray-600 body-font" id="main">
  <div className="container mx-auto max-w-7xl flex px-5 py-48 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
     <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
      <h1 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">{data.sanityLevelOne.title}
      </h1>
      <p className="sm:text-lg mb-8 leading-relaxed">Ein wichtiger Baustein für Handel und Verbraucher sind die Online Marktplätze. Kunden können "unter einem Dach" die Angebote unterschiedlicher Anbieter vergleichen und von niedrigen Preisen profitieren. Als Anbieter können Sie über die Anmeldung auf einem Onlinemarktplatz in vergleichsweise sehr kurzer Zeit Ihre Reichweite vervielfachen. Da die meisten etablierten Marktplätze durch ihre Markenreputation und die häufig überaus kundenfreundlichen Marktplatzrichtlinien hohes Vertrauen bei der Käuferschaft genießen, bevorzugen sicherheitsbewußte Verbraucher den Einkauf auf Online-Marktplätzen gegenüber kleineren Anbietern.
      Sie möchten mit Ihrem Unternehmen erfolgreich am Marktplatzhandel teilnehmen?
      Wir verfügen über einen reichhaltigen Erfahrungsschatz aus der erfolgreichen Abwicklung zahlreicher Kundenprojekte im Bereich Omnichannel/Onlinemarktplätze. <br/><br/>

       

</p>

    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      
    <Image {...data.sanityLevelOne.image} height={250}    style={{
width: '80%',
objectfit: 'fill',
opacity: '1',
transition: 'opacity 500ms ease 0s'
}} alt="Logos Online Marktplätze"  />
    </div>
  </div>
</section>   





<section className="text-gray-600 body-font">
  <div className="container mx-auto max-w-7xl px-5 pt-48  flex flex-wrap justify-items-center">
    <div className="md:w-2/5 mb-3 grow md:grow-0 flex ">
      <div>

      <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="current" className="w-6 h-6 stroke-black">
      <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
    </svg>
    </div>
    

  </div>
    <div>
    <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Welcher Marktplatz passt zu mir?</h2>
    </div>
</div>
    <div className="md:w-3/5 md:pl-6">
      <p className="leading-relaxed text-base sm:text-lg">Neben Marktplätzen die „Alles für Jeden“ bieten, wie beispielsweise Amazon oder Ebay, liefern spezialisierte Plattformen wie Möbel24 oder Zalando branchenspezifische Lösungen. Bei der Frage, welche Marktplätze für Ihre Anforderungen die beste Wahl sind, bieten wir fundierte Entscheidungshilfen basierend auf langjähriger Erfahrung und gründlicher Untersuchung aller relevanten Faktoren wie Produktangebot, Konkurrenzsituation und gegebenenfalls Einstiegsbarrieren.
      </p>

    </div>
  </div>
</section>



<section className="text-gray-600 body-font">
  <div className="container px-5 max-w-7xl py-48 mx-auto flex flex-wrap justify-items-center">
    <div className="md:w-2/5 mb-3 grow md:grow-0 flex">
  <div>

  <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
  <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
</div>



  </div>
<div>

<h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Technische Integration</h2>
</div>
</div>
    <div className="md:w-3/5 md:pl-6">
      <p className="leading-relaxed text-base sm:text-lg">In den wenigsten Fällen ist der erfolgreiche Handel auf Online-Marktplätzen ohne die Nutzung von externen Systemen möglich oder empfehlenswert. Ihr Produktangebot mitsamt Beständen muss irgendwie den Weg in die Systeme des jeweiligen Marktplatzes finden. Genauso erfordert die Bearbeitung der eingegangenen Bestellungen in der Regel zusätzliche Software, um eine dem Bestellaufkommen entsprechend effiziente Auftragsabwicklung gewährleisten.

Die Auswahl der für Ihre Bedürfnisse passenden Systeme und Schnittstellenlösungen sind grundlegende Erfolgsfaktoren für einen reibungslosen Start und profitablen Betrieb Ihrer Marktplatz Performance. Wir beraten plattformunabhängig und setzen die für Sie optimale Kombination um.

      </p>
      <div className="flex md:mt-4 mt-6">
        
        <a class="text-primary inline-flex items-center ">Mehr über die technische Integration von Onlinemarktplätzen erfahren 
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
            <path d="M5 12h14M12 5l7 7-7 7"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</section>

<aside className="bg-secondary">
    <div className="container px-5 max-w-7xl flex flex-col md:flex-row md:flex-wrap gap-8 items-center py-8  mx-auto  lg:py-16 lg:px-6 justify-center		">
        <div className="md:w-12/12 md:grow">
        <h2 className="text-xs text-primary tracking-widest font-medium title-font mb-1">Attraktiv für Anbieter in jeder Größe</h2>
            <h2 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-slate-400">Umsatzmotor Onlinemarktplatz</h2>
            <p className="mb-8  leading-relaxed text-white">Viele gute Gründe sprechen für einen Einstieg in den Onlinehandel auf Marktplatzplattformen wie Amazon, Ebay & Co. Das Potenzial, durch die dortige Präsenz eine außerordentlich große Käuferschaft zu erreichen gehört hierbei sicherlich mit zu den gewichtigsten Punkten. Anschaulich wird die immense Reichweite durch einen Blick auf die Umsatz- und Wachstumszahlen der Top Onlineshops in Deutschland:
Rund die Hälfte integriert Marktplätze für Dritthändler, darunter die vier Erstplatzierten Amazon, Otto, Mediamarkt und Zalando. Marktplätze sind ganz vorne mit dabei und insbesondere Spitzenreiter Amazon prägt die E-Commerce Landschaft für alle beteiligten Parteien. Auch für etablierte Marken und Shops sind Marktplätze ein beliebtes Standbein: Im Jahr 2021 unterhielten mehr als 50% der Top 100 Onlineshops eine Präsenz auf mindestens einer dieser Plattformen: Amazon, Ebay, Kaufland.
</p>
            <p className="leading-relaxed text-light-on-dark">Quelle: <a href="https://www.ehi.org/news/marktplaetze-umsatztreiber-des-onlinehandels/" title="EHI Retail Institue" target="__blank">EHI Retail Institute</a></p>
        </div>
        <div className="md:w-12/12 gap-6 lg:w-10/12 lg:justify-self-center	flex flex-col md:flex-row mt-8 md:flex overflow-hidden">
            <div className="md:w-6/12 md:flex-grow"> 
            <img className="rounded-lg" src="/Infografik_top10shops.jpg" alt="office content 2"/>

            </div>
            <div className="md:w-6/12 md:flex-grow px-5">
            <img className=" rounded-lg" src="/Infografik_marktplaetze.jpg" alt="office content 1 px-5"/>

            </div>
        </div>
    </div>
</aside>

<section className="text-gray-600 body-font">
  <div className="container max-w-7xl px-5 pt-48 mx-auto flex flex-wrap justify-items-center">
    <div className="md:w-2/5 mb-3 grow md:grow-0 flex">
    <div>

      <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">


          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-black">
            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
          </svg>
    </div>
  </div>
  <div>

<h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Kundenservice</h2>
</div>
</div>
    <div className="md:w-3/5 md:pl-6">
      <p className="leading-relaxed text-base sm:text-lg">Mit dem Marktplatzgeschäft steigen nicht nur das Bestellaufkommen und die Umsätze. Kundenanfragen und häufig auch Reklamationen erfordern erhöhte Aufmerksamkeit und können so zu Spitzenzeiten Ihre bestehenden Kapazitäten auch gerne mal überlasten. Damit Ihre Anfragen im Rahmen der jeweiligen Marktplatzvorgaben auch zur Hochsaison mit höchstmöglicher Kundenzufriedenheit gelöst werden, kümmern wir uns mit erfahrenen und bestens geschulten Fachkräften um die Anfragen Ihrer Marktplatzkunden.
      </p>
    </div>
  </div>
</section>

<section className="text-gray-600 body-font">
  <div className="container max-w-7xl px-5 py-48 mx-auto flex flex-wrap justify-items-center">
    <div className="md:w-2/5 mb-3 grow  md:grow-0 flex ">
      <div className="">
      <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
</svg>





      </div>

      </div>






<div className="">

<h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Marketing und Wachstum</h2>

</div>
</div>
    <div className="md:w-3/5 md:pl-6">
      <p className="leading-relaxed text-base sm:text-lg">
      Ihre Marktplatzumsätze allein dem Zufall zu überlassen wäre selbstverständlich keine empfehlenswerte Strategie. Auf einigen Plattformen stehen Ihnen diverse Stellschrauben zur Verfügung, durch deren Einsatz Sie Ihre Sichtbarkeit und Verkaufszahlen teilweise beträchtlich steigern können. Dies kann beispielsweise über die Optimierung Ihrer Angebote geschehen: ein Angebot mit zahlreichen informativen Produktabbildungen und einer detaillierten, ansprechend formulierten Produktbeschreibung hat beste Voraussetzungen auf relevanten Suchseiten prominent platziert zu werden.
 
 Ein weiterer wichtiger und zugleich wirksamer Ansatz ist das Anlegen von Werbekampagnen auf Marktplatzebene. Nicht alle Marktplätze bieten die Möglichkeit, die Produktsichtbarkeit über bezahlte Produkteinblendungen zu erhöhen. Auf dem wichtigsten Marktplatz (Amazon) jedoch spielt die klickbasierte Werbung eine wichtige Rolle in allen Phasen des Produktlebenszyklus. 
 
      </p>
    </div>
  </div>
</section>







<section className="text-gray-50 body-font bg-dark bg-center bg-wavy bg-no-repeat bg-cover">
    <div  id="contacts" className="container px-5 py-24 mx-auto flex flex-col mb-24 md:mb-48">
      <div className="lg:w-4/6 mx-auto">
        {/* <div className="rounded-lg h-64 overflow-hidden">
          <img alt="content" className="object-cover object-center h-full w-full" src="https://dummyimage.com/1200x500"/>
        </div> */}
        <div className="flex flex-col sm:flex-row mt-10">
        <ContactDetails/>
          <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-primary sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left ">
            <p className="leading-relaxed text-lg mb-4 text-white">Sind Sie bereit für Ihr Omnichannel Projekt? Wir beraten plattformunabhängig mit Fokus auf Ihre individuellen Ziele und Anforderungen. Sichern Sie sich noch heute Ihr unverbindliche Erstgespräch. Mein Team und ich freuen uns auf Sie!  </p>
            <a className="cursor-pointer text-primary inline-flex items-center">Jetzt Erstgespräch sichern
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
            <div className="mark">
        

    </div>
          </div>
        </div>
      </div>
    </div>
</section>

<Topics/>

<Footer/>
</>

    )
}

export default aboutPage;

export const Head = ({data}) => {
  return (
  <>
  
  {/* <meta name="description" content="This is an example of a meta description. This will often show up in search results."/> */}
  {/* <title> </title> */}
  <SEO/>
  </>
  )
}


export const page = graphql`
query MyNewQuery {

  sanityLevelOne(id: {eq: "-857f0b4e-f240-56a2-b8ac-3456d66ed725"}) {
    title
    intro_content
    image {
      ...ImageWithPreview
    }
  }
}


`