import React from "react";
import { useStaticQuery,Link, graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";




const Topics = ()=>{

    const data = useStaticQuery(graphql`
     query TopicsQuery {
        sanityPage(id: {eq: "-3414d425-da87-50fe-87d9-fade4982e897"}) {
    image  {
        ...ImageWithPreview
    }  
    imageTwo {
        ...ImageWithPreview
    }
    imageThree {
        ...ImageWithPreview
    }
    
  }

     }
    `)

return (

    <>
    <section className=" body-font b">
        
        <div className="container px-5 py-36 mx-auto max-w-7xl">
        <div class="w-24 h-1 bg-primary rounded mt-2 mb-4 mx-auto"></div>
        <h2 className="title-font sm:text-3xl text-3xl text-center mb-24">Mehr zu unseren Dienstleistungen</h2>
        <div className="flex flex-wrap -m-4 text-gray-600">
            <div className="p-4 md:w-1/3 drop-shadow-md drop-shadow-md">
            <div className="h-full flex flex-col border-0  rounded-lg  overflow-hidden bg-white">
            <Image {...data.sanityPage.image} width={302} height={192}     style={{


        width: '100%',

        objectFit: 'cover',


        opacity: '1',
        transition: 'opacity 500ms ease 0s'

        }} alt="Amazon rundum"  />
                <div className="p-6 flex flex-col grow bg-white">
                {/* <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2> */}
                <h1 className="title-font text-lg font-medium mb-3">Amazon Rundum</h1>
                <p className="grow leading-relaxed mb-3">Für Amazon Marktplatzhändler und alle, die es werden wollen: unser umfassendes Angebot an Dienstleistungen rund um den größten und wichtigsten Onlinemarktplatz helfen Ihnen dabei sicher und schnell ans Ziel zu kommen.</p>
                <div className="py-6 flex items-center flex-wrap ">
                    <Link className="text-primary inline-flex items-center md:mb-2 lg:mb-0" to="/amazon">Amazon Dienstleistungen
                    <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-lineJoin="round">
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                    </svg>
                    </Link>
                    
                </div>
                </div>
            </div>
            </div>
            <div className="p-4 md:w-1/3 drop-shadow-md">
            <div className="h-full  bg-white flex flex-col border-0  rounded-lg overflow-hidden">
            <Image {...data.sanityPage.imageTwo} width={302} height={192}     style={{


        width: '100%',

        objectFit: 'cover',


        opacity: '1',
        transition: 'opacity 500ms ease 0s'

        }} alt="Marktplätze"  />
        <div className="p-6  flex flex-col">
          {/* <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2> */}
          <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Marktplätze und Multichannel</h1>
          <p className="grow leading-relaxed mb-3">Auf Knopfdruck neue Käufergruppen und Gebiete erreichen?
Wir helfen bei der Auswahl und technischen Anbindung der für Ihr Geschäftsfeld wichtigen Marktplätze und bieten rundum Unterstützung für den laufenden Betrieb und die Optimierung.

</p>
<div className="py-6 flex items-center flex-wrap ">
            <Link to="/marktplaetze" className="text-primary inline-flex items-center md:mb-2 lg:mb-0">Marktplatz Services
              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-lineJoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </Link>

          </div>

        </div>

      </div>
    </div>
    <div className="p-4 md:w-1/3 drop-shadow-md ">
      <div className="h-full bg-white flex flex-col border-0 rounded-lg overflow-hidden">
      <Image {...data.sanityPage.imageThree} width={302} height={192}     style={{


width: '100%',

objectFit: 'cover',


opacity: '1',
transition: 'opacity 500ms ease 0s'

}} alt="Einstieg"  />
        <div className="p-6 grow flex flex-col">
          {/* <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2> */}
          <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Ihr Einstieg in den Onlinehandel</h1>
          <p className="grow  leading-relaxed mb-3">Neben immensen Wachstumschancen lauern im E-Commerce auch zahlreiche Fallstricke und Risiken. Eine solide Planung und sorgfältige Auswahl der richtigen Werkzeuge ist die Basis für Ihren zukünftigen Erfolg.</p>
          <div className="py-6 flex items-center flex-wrap ">
            <Link to="/ecommerce" className="text-primary inline-flex items-center md:mb-2 lg:mb-0">E-Commerce Einstieg
              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-lineJoin="round">
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </Link>

          </div>

        </div>

      </div>
    </div>
  </div>
</div>
</section>
    </>    

)

}

export default Topics;